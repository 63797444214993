<template lang="pug">
v-container
  v-overlay(opacity=".9" color="#f3f3f3" v-if="book==''").pl-lg-10
      v-progress-circular.ml-lg-15(
        :size="70"
        :width="7"
        color="primary"
        indeterminate)
  v-card(v-else)
    v-spacer
    v-card-title.headline.justify-center.mt-3 Edit Book Details
    v-spacer
    v-divider
    v-form(ref="form")
      div.px-4
          v-text-field.pt-5(
            label="Book Title*",
            outlined,
            clearable,
            v-model="book.title",
            :rules="rules"
          )
      v-row
        v-col.text-center(cols="5")
         
          v-btn.mb-n15(
            @click="imageUpload = !imageUpload",
            color="primary",
            dark,
            small,
            style="z-index: 2",
          ) Upload Cover
          v-dialog(v-model="imageUpload", width="600")
            v-card.text-center.pa-5.mr-n5.text-center(height="200")
              h4 Upload image
              br
              input(type="file", @change="previewImage", accept="image/*")
              br

              v-btn(
                @click="onUpload(); imageUpload = false",
                v-if="imageData != null",
                color="success",
                small,
                :loading="loading8"
              ) Upload
              v-btn(disabled, small, v-else) Upload
          .text-center.mt-n3(v-if="uploadValue == 100")
            img(
              :src="picture",
              style="width: 70%; height: 450px; object-fit: cover"
            )
          v-card.mx-auto.text-center.pt-15.mt-n3(
            v-else,
            width="70%",
            height="380px",
            outlined
          )
            h6.font-weight-light.text-muted 125x180
            v-progress-circular#progress(
              :size="100",
              :width="15",
              :value="uploadValue",
              color="primary"
            ) 
              v-icon.text-muted(large) mdi-file-image
            p.font-weight-bold {{ uploadValue.toFixed() + '%' }}

        v-col.ml-n12(cols="7")
          //-  PDF NAME
          v-row
                  v-col(cols="3")
                    v-card-subtitle.font-weight-medium.mt-n1 PDF File*
                  v-col(cols="9")
                    v-text-field.ml-n13(
                      outlined,
                      v-model="book.pdfUrl",
                      :rules="[(v) => !!v || 'PDF File name is required.',v => /.+.pdf+/.test(v) || 'Must end with .pdf']",
                      @keyup.enter="checkPdf()"
                      @focusout="checkPdf()"
                      hint="click Enter to check if the file name is correct and valid"
                    ) 
          //- Author
          v-row 
            v-col(cols="3")
              v-card-subtitle.font-weight-medium.mt-n1 Author*
            v-col(cols="9") 
              v-combobox.ml-n13(
                multiple,
                outlined,
                small-chips
                :rules="rules",
                v-model="book.author",
              )
          //- Publisher
          v-row 
            v-col(cols="3")
              v-card-subtitle.font-weight-medium.mt-n1 Publisher*
            v-col(cols="9")
              v-text-field.ml-n13(
                outlined,
                v-model="book.publisher",
                :rules="rules",
              )
          
          //- Year
          v-row 
            v-col(cols="3")
              v-card-subtitle.font-weight-medium.mt-n1 Year*
            v-col(cols="9")
              v-text-field.ml-n13(
                v-model.number="book.year",
                type="number",
                outlined,
                min="1800"
              )
          //- Edition
          v-row 
            v-col(cols="3")
              v-card-subtitle.font-weight-medium.mt-n1 Edition
            v-col(cols="9")
              v-text-field.ml-n13(
                v-model="book.edition",
                outlined
              )
            //- Subject
          v-row 
            v-col(cols="3")
              v-card-subtitle.font-weight-medium.mt-n1 Subject
            v-col(cols="9")
              v-select.ml-n13(
                :items="subjects",
                v-model="book.subject",
                outlined
                multiple,
                small-chips
              )
            //- Institution
          v-row 
            v-col(cols="3")
              v-card-subtitle.font-weight-medium.mt-n1 Institution
            v-col(cols="9")
              v-select.ml-n13(
                :items="institutions",
                outlined,
                multiple,
                small-chips
                v-model="book.institution",
              )
          //-  ISBN
          v-row 
            v-col(cols="3")
              v-card-subtitle.font-weight-medium.mt-n1 ISBN
            v-col(cols="9")
              v-text-field.ml-n13(
                outlined,
                placeholder="ISBN-XXX-XXXXX-XXXXXXX-XXXXXX-X",
                v-model="book.isbn"
              ) 
          //- DOI 
          v-row 
            v-col(cols="3")
              v-card-subtitle.font-weight-medium.mt-n1 DOI
            v-col(cols="9")
              v-text-field.ml-n13(
                outlined,
                placeholder="10.XXXX/XXXX",
                v-model="book.doi"
              )
      v-divider
      v-card-actions.mr-6
        v-spacer
        v-btn.ml-auto.mb-3(
          text,
          color="grey",
          width="120px",
          to="/admin/books"
        ) Cancel
        v-btn.mb-3.mr-15(
          dark,
          color="rgba(2, 0, 115, 0.8)",
          :disabled="uploadValue != 100",
          @click="updateBook(id)",
          :loading="loading"
        ) Save
</template>
<script>
import firebase from "firebase/app";
import "firebase/functions";
let getBook = firebase.functions().httpsCallable("getBook");
let updateBook = firebase.functions().httpsCallable("updateBook");
export default {
  data() {
    return {
      book: "",
      id: this.$route.params.id,
      imageUpload: false,
      isbn: "",
      loading: false,
      loading8: false,
      doi: "",
      edition: "",
      subjects: [
        "Biology",
        "Chemistry",
        "Environmental Science",
        "Forensic Science",
        "General Science",
        "Geological Science",
        "Marine Science",
        "Material Science",
        "Mathematics",
        "Molecular Biology & Biotechnology",
        "Physics",
      ],
      institutions: [
        "Institute of Biology",
        "Institute of Chemistry",
        "Institute of Environmental Science and Meteorology",
        "Institute of Mathematics",
        "Marine Science Institute",
        "Materials Science and Engineering Program",
        "National Institute of Geological Sciences",
        "National Institute of Molecular Biology and Biotechnology",
        "National Institute of Physics",
      ],
      imageData: null,
      picture: "",
      uploadValue: 100,
      subjectArray: [],
      institutionArray: [],
      rules: [(v) => v.length != 0 || "Required."],
      email: "",
    };
  },
  async created() {
    const id = this.$route.params.id;
    this.book = (await getBook({ id })).data.book;
    this.picture = this.book.imageUrl;
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(() => {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(async (tokenResult) => {
            this.email = tokenResult.claims.email;
          });
      });
    // let xhr = new XMLHttpRequest();
    // xhr.open(
    //   "GET",
    //   `https://ehubstorage.cslib.upd.edu.ph/home/pdf/${this.book.pdfUrl}`
    // );
    // xhr.send();
    // xhr.onreadystatechange = function() {
    //   if (this.readyState == this.HEADERS_RECEIVED) {
    //     var contentType = xhr.getResponseHeader("Content-Length");
    //     console.log(contentType / 1000000);
    //   }
    // };
  },

  methods: {
    async updateBook(id) {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await updateBook({
          id,
          updatedData: {
            author: this.book.author,
            title: this.book.title,
            publisher: this.book.publisher,
            institution: this.book.institution,
            subject: this.book.subject,
            year: Number(this.book.year),
            edition: this.book.edition,
            imageUrl: this.picture,
            pdfUrl: this.book.pdfUrl,
            date: Date.now(),
            isbn: this.book.isbn,
            doi: this.book.doi,
            addedBy: this.book.addedBy,
            modifiedBy: this.email,
          },
        });
        this.loading = false;
        this.$router.push("/admin/books");
        this.flashMessage.success({
          title: "Book was modified successfully",
          message: "You successfully modified the book!",
          icon:
            "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
        });
      } else {
        this.$store.dispatch("setSnackbar", {
          text: "  Please fill the required fields!",
          icon: "mdi-block-helper",
          iconColor: "error",
        });
      }
    },
    checkPdf() {
      var url = `https://ehubstorage.cslib.upd.edu.ph/home/pdf/${this.book.pdfUrl}`;
      try {
        var http = new XMLHttpRequest();
        http.open("HEAD", url, false);
        http.send();
        if (http.status != 404) {
          this.$store.dispatch("setSnackbar", {
            text: "  File name is correct and valid.",
            icon: "mdi-check",
            iconColor: "success",
          });
        } else {
          alert("not valid");
        }
      } catch (err) {
        this.$store.dispatch("setSnackbar", {
          text: "  File name is either incorrect or does not exist.",
          icon: "mdi-block-helper",
          iconColor: "error",
        });
      }
    },

    previewImage(event) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },
    onUpload() {
      this.loading8 = true;
      this.picture = null;
      //create reference to storage and storage file
      const storageRef = firebase
        .storage()
        .ref(`images/${this.imageData.name}`) //ref is directory of file in console
        .put(this.imageData);
      //progress bar
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          //downloading photo url for front-end display
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            //put upload to database code

            this.picture = url;
          });
        }
      );
      this.loading8 = false;
    },
  },
};
</script>
<style></style>
